import React, {useState} from 'react';
import './Contact.css';
import { useTranslation } from 'react-i18next';
import '../../i18n.js';
import { useRevealUp } from '../../components/Animation/RevealUp/RevealUp.js'; 
import { Link } from 'react-router-dom';

const Contact = () => {
    const { t } = useTranslation();
    useRevealUp('.revealUp');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [company, setCompany] = useState('');
    const [message, setMessage] = useState('');

    const generateWhatsAppLink = () => {
        const whatsappNumber = '6281318541847'; // Nomor WhatsApp tujuan
        const waLink = `https://wa.me/${whatsappNumber}?text=Nama%20Lengkap%3A%20${encodeURIComponent(name)}%0AEmail%3A%20${encodeURIComponent(email)}%0ANomor%20Handphone%3A%20${encodeURIComponent(phone_number)}%0APerusahaan%3A%20${encodeURIComponent(company)}%0APesan%3A%20${encodeURIComponent(message)}`;
        return waLink;
    };

    return (    
        <div className="my-16">
            <div className="justify-center items-center text-center mb-10 mx-2 revealUp xl:mx-32">
                <div className="text-header-1 text-4xl md:text-6xl font-bold mb-3"style={{ lineHeight: 1.3 }}>{t('contact_title_up')}</div>
                <div className="text-4xl md:text-6xl font-bold mb-3">{t('contact_title_down')}</div>
                <div className="text-black text-xl font-semibold">{t('contact_desc')}</div>
            </div>

            {/* <div class="grid gap-x-8 gap-y-4 grid-cols-2 flex justify-center mx-32">
                <div>
                    <div className="font-bold text-4xl">Office</div>
                    <br></br>
                    <div className="text-lg mb-3">
                        OFFICE :
                        18 OFFICE PARK, LT. 25, SUITE A 2, JL. TB SIMATUPANG KAV. 18, JAKARTA SELATAN.
                    </div>
                    <div className="text-red-600">Show On Map</div>
                    
                    <br></br>
                    <div className="font-bold text-4xl">Workshop</div>
                    <br></br>
                    <div className="text-lg mb-3">
                        WORKSHOP :
                        KAWASAN INDUSTRI SENTUL OLIMPIC CBD, Jl. Olympic Raya No.10 Kav. H6, Leuwinutug, Citeureup, Bogor Regency, West Java 168100
                    </div>
                    <div className="text-red-600">Show On Map</div>
                </div>
                <div className="border border-gray-300 rounded-2xl items-center text-center p-4">
                    <input name="name" placeholder="Name" class="mt-1 mb-5 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>
                    <input name="email_address" placeholder="Email Address" class="mt-1 mb-5 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>
                    <input name="phone_number" placeholder="Phone Number" class="mt-1 mb-5 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>
                    <input name="company_name" placeholder="Company Name" class="mt-1 mb-5 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>
                    <textarea name="message" placeholder="Message / Project Goals" class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>
                    <br></br>
                    <a href="https://reactjs.org" target="_blank" rel="noopener noreferrer" className="btn-submit-contact font-bold text-white py-2 px-4 rounded-[12px] text-lg hover:bg-red-800">&nbsp;&nbsp;Submit&nbsp;&nbsp;</a>
                </div>
            </div> */}

            <div className="flex flex-wrap font-semibold text-lg mobile-view mx-32">
                <div className="w-full">
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-2/5 pr-5">
                            <div className="font-bold text-4xl md:text-4xl lg:text-6xl">{t('contact_office')}</div>
                            <br></br>
                            <div className="text-lg mb-3">
                                {t('contact_office')} :
                                18 OFFICE PARK, LT. 25, SUITE A 2, JL. TB SIMATUPANG KAV. 18, JAKARTA SELATAN.
                            </div>
                            <div className="text-red-600">
                                <a href="https://maps.app.goo.gl/2fJAXWAJq21zTs917" target="_blank" rel="noopener noreferrer">
                                    {t('contact_map')}
                                </a>
                            </div>
                            
                            <br></br>
                            <div className="font-bold text-4xl md:text-4xl lg:text-6xl">{t('contact_workshop')}</div>
                            <br></br>
                            <div className="text-lg mb-3">
                                {t('contact_workshop')} :
                                KAWASAN INDUSTRI SENTUL OLIMPIC CBD, Jl. Olympic Raya No.10 Kav. H6, Leuwinutug, Citeureup, Bogor Regency, West Java 168100
                            </div>
                            <div className="text-red-600">
                                <a href="https://maps.app.goo.gl/jS9ZnSKt4Q8tZcC58" target="_blank" rel="noopener noreferrer">
                                    {t('contact_map')}
                                </a>
                            </div>
                        </div>
                        <div className="w-full md:w-3/5 border border-gray-300 rounded-2xl items-center text-center p-4 h-[40rem] mt-10 sm:mt-10 md:mt-0">
                            <input name="name" value={name}  onChange={(e) => setName(e.target.value)} placeholder={t('contact_name')} className="h-[4rem] mt-1 mb-5 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>
                            <input name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t('contact_email')} className="h-[4rem] mt-1 mb-5 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>
                            <input name="phone_number" value={phone_number} onChange={(e) => setPhoneNumber(e.target.value)} placeholder={t('contact_phone')} className="h-[4rem] mt-1 mb-5 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>
                            <input name="company" value={company} onChange={(e) => setCompany(e.target.value)} placeholder={t('contact_company')} className="h-[4rem] mt-1 mb-5 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>
                            <textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)} placeholder={t('contact_message')} className="h-[13rem] mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>
                            <br></br>
                            <Link to={generateWhatsAppLink()} target="_blank" rel="noopener noreferrer" className="btn-submit-contact font-bold text-white py-2 px-4 rounded-[12px] text-lg hover:bg-red-800">
                                &nbsp;&nbsp;{t('contact_submit')}&nbsp;&nbsp;
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;